@import '../Styles/variables';

@mixin gap($gap) {
    gap: $gap;

    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        & {
            margin-top: -$gap;
            margin-left: -$gap;
        }

        & > * {
            margin-top: $gap;
            margin-left: $gap;
        }
    }
}

.extraSmall {
    @include gap(var(--distance-extra-small));
}

.small {
    @include gap(var(--distance-small));
}

.medium {
    @include gap(var(--distance-medium));
}

.large {
    @include gap(var(--distance-large));
}
