@import '~/bb/ui/Styles/functions';

$spacingUnit: spacing(4);

.sideMenuList {
    display: flex;
    flex-direction: column;
    gap: $spacingUnit;
    padding-inline-start: 0;
    list-style: none;
}

.sideMenuListIsNested {
    padding-inline-start: $spacingUnit;
    margin-top: $spacingUnit;
}

.sideMenuListItem {
    text-align: left;
    list-style: none;
}
