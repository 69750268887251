@import '~/bb/ui/Styles/functions';

.content {
    position: relative;
    z-index: 1;
    display: flex;
    gap: spacing(3);
    align-items: center;
    justify-content: center;
}

.block {
    display: block;
}

.fluid {
    width: 100%;
}
