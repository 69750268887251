@import '~/bb/ui/Styles/variables';

$size: 20px;

.container {
    input:checked ~ & {
        .input {
            background-color: var(--color-primary-purple);
            background-image: url('/images/svg/check-black.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            border-color: var(--color-primary-purple);
        }
    }

    input:disabled ~ & {
        cursor: not-allowed;

        .input {
            background-color: var(--color-gray-02);
            border-color: var(--color-gray-02);
        }
    }

    input:focus-within ~ & {
        .input {
            outline: 2px solid var(--color-focus);
            outline-offset: 2px;
        }
    }

    *:hover ~ & {
        .input {
            background-color: var(--color-tertiary-purple);
        }
    }

    input[aria-invalid='true'] ~ & {
        .input {
            border-color: var(--color-secondary-red);
        }
    }
}

.input {
    display: inline-block;
    flex-shrink: 0;
    width: $size;
    height: $size;
    background-color: var(--color-primary-white);
    border: 1px solid var(--color-primary-black);
    transition:
        background-color 0.2s,
        background-image 0.2s,
        border-color 0.2s;
}
