@import '../Styles/variables';

.base {
    display: block;
    width: 100%;
    font-weight: bold;
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.alert {
    color: var(--color-primary-white);
    background-color: var(--color-secondary-red);
}

.warning {
    color: var(--color-primary-black);
    background-color: var(--color-secondary-yellow);
}

.info {
    color: var(--color-primary-black);
    background-color: var(--color-tertiary-purple);
}

.icon {
    margin-left: 20px;
}
