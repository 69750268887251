.flexStart {
    align-items: flex-start;
}

.flexEnd {
    align-items: flex-end;
}

.center {
    align-items: center;
}

.stretch {
    align-items: stretch;
}
