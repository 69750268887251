@keyframes loading {
    0% {
        transform: translateX(-101%);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
