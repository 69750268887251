.input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.root {
    position: relative;
    cursor: pointer;
}
