.base {
    display: flex;

    &.row {
        flex-direction: row;
    }

    &.column {
        flex-direction: column;
    }

    &.wrap {
        flex-wrap: wrap;
    }
}
