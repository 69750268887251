@import '../Styles/variables';

.padding {
    padding: 0 var(--distance-small);

    @media (min-width: $screen-size-md) {
        padding: 0 var(--distance-medium);
    }
}

.flex {
    display: flex;
}
