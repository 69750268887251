@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    display: flex;
    align-items: center;
    height: 24px;
}

.crumb {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.text {
    max-width: 20ch;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: $screen-size-sm) {
        max-width: 14ch;
    }
}

.navBreadcrumbs {
    height: 52px;
}
