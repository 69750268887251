@import '~/bb/ui/Styles/variables';

@mixin translateImg {
    transition: transform 0.6s cubic-bezier(1, 0, 0, 1);
    transition-delay: 0.2s;
    transform: translateX(-100%);
}

.base {
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.animationSlide {
    position: relative;
    width: inherit;
    height: inherit;
    background-color: var(--color-primary-purple);

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--color-primary-white);
    }

    &.show {
        transform: translateX(calc(0%));
        animation: scaleImage 0.6s cubic-bezier(1, 0, 0, 1);
        animation-delay: 0.45s;
        animation-direction: reverse;

        &::before {
            @include translateImg;
        }
    }
}

@keyframes scaleImage {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
    }
}
