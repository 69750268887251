.root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-modal);
    overflow-y: auto;

    &[data-isopen='false'] {
        pointer-events: none;
    }
}

.content {
    position: relative;
    flex: 1;
}
