@use 'sass:color';

@import '~/bb/ui/Styles/variables';

.root {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-modal);
    width: 100vw;
    height: 100vh;

    // background-color: rgba(0, 0, 0, 0.8);
    background-color: color.adjust($color-primary-black, $alpha: -0.2);
}
