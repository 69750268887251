@import '../Styles/variables';

.none {
    padding-right: 0;
}

.extraSmall {
    padding-right: var(--distance-extra-small);
}

.small {
    padding-right: var(--distance-small);
}

.medium {
    padding-right: var(--distance-medium);
}

.large {
    padding-right: var(--distance-large);
}

.frameOuter {
    padding-right: 90px;
}

.frameInner {
    padding-right: 60px;
}
