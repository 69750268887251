@import '~/bb/ui/Styles/variables';

.fluid {
    width: 100%;
}

.imageBorderPadding {
    padding: 4rem;

    @media screen and (min-width: $screen-size-md) {
        padding: 6rem;
    }
}

.col50 {
    --span: 12;

    @media (min-width: $screen-size-md) {
        --span: 6;
    }
}

.flex50 {
    flex: 1 0 100%;

    @media screen and (min-width: $screen-size-md) {
        flex: 1 0 50%;
    }
}

.hMax600 {
    max-height: 600px;
}

.hMin400 {
    display: flex;
    min-height: 400px;
}

.overflowHidden {
    overflow: hidden;
}

.noPadding {
    padding: 0;
}

.noMargin {
    margin: 0;
}

.heroMargin {
    @media screen and (min-width: $screen-size-md) {
        margin: var(--distance-large) 0 var(--distance-large) 0;
    }
}

.breakWord {
    word-break: normal;
    overflow-wrap: anywhere;

    @supports not (overflow-wrap: anywhere) {
        word-break: break-word;
    }
}

@mixin pagePadding {
    padding-right: 20px !important;
    padding-left: 20px !important;

    @media screen and (min-width: $screen-size-lg) {
        padding-right: 60px !important;
        padding-left: 60px !important;
    }
}

.pagePadding {
    @include pagePadding;
}

.pagePaddingToXs {
    @media screen and (max-width: $screen-size-xs) {
        @include pagePadding;
    }
}

.pagePaddingToSm {
    @media screen and (max-width: $screen-size-sm) {
        @include pagePadding;
    }
}

.pagePaddingToMd {
    @media screen and (max-width: $screen-size-md) {
        @include pagePadding;
    }
}

.pagePaddingToLg {
    @media screen and (max-width: $screen-size-lg) {
        @include pagePadding;
    }
}

.pagePaddingToXl {
    @media screen and (max-width: $screen-size-xl) {
        @include pagePadding;
    }
}

.pagePaddingToXxl {
    @media screen and (max-width: $screen-size-xxl) {
        @include pagePadding;
    }
}

.headerFluid {
    font-size: clamp(2.25rem, calc(0.1rem + 4.9vw), 4rem);

    &.extraSmall {
        font-size: clamp(1.875rem, calc(0.1rem + 4.2vw), 3.4rem);
    }
}

.block {
    display: block;
}

.lowercase {
    text-transform: lowercase;
}

.grow {
    flex-grow: 1;
}
