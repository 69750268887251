@use 'sass:meta';

@import '~/bb/ui/Styles/variables';
@import 'normalize.css';

:root {
    --optimizely-visibility: hidden;

    /* Colors */
    --color-primary-purple: #{$color-primary-purple};
    --color-primary-black: #{$color-primary-black};
    --color-primary-white: #{$color-primary-white};
    --color-secondary-red: #{$color-secondary-red};
    --color-secondary-yellow: #{$color-secondary-yellow};
    --color-secondary-green: #{$color-secondary-green};
    --color-tertiary-purple: #{$color-tertiary-purple};
    --color-tertiary-red: #{$color-tertiary-red};
    --color-tertiary-yellow: #{$color-tertiary-yellow};
    --color-tertiary-green: #{$color-tertiary-green};
    --color-gray-00: #{$color-gray-00};
    --color-gray-01: #{$color-gray-01};
    --color-gray-02: #{$color-gray-02};
    --color-gray-03: #{$color-gray-03};
    --color-gray-04: #{$color-gray-04};
    --color-gray-05: #{$color-gray-05};
    --color-gray-06: #{$color-gray-06};
    --color-transparent: #{$color-transparent};
    --color-focus: #{$color-focus};

    /* Spacings */
    --base-spacing-unit: #{$base-spacing-unit};
    --distance-extra-small: #{$distance-extra-small};
    --distance-small: #{$distance-small};
    --distance-medium: #{$distance-medium};
    --distance-large: #{$distance-large};

    /* Font */
    --font-primary: #{meta.inspect($font-primary)};
    --font-secondary: #{meta.inspect($font-secondary)};
    --font-base-size: #{$font-base-size};
    --font-size-text-small: #{$font-size-text-small};
    --font-size-text-medium: #{$font-size-text-medium};
    --font-size-text-large: #{$font-size-text-large};
    --font-size-text-extra-large: #{$font-size-text-extra-large};

    /* Size */
    --button-height: #{$button-height};
    --button-height-small: #{$button-height-small};
    --navbar-height: #{$navbar-height};
    --navbar-height-slim: #{$navbar-height-slim};
    --input-height: #{$input-height};
    --input-adornment-size: #{$input-adornment-size};
    --input-spacing: #{$input-spacing};
    --input-border-color: #{$input-border-color};

    /* Misc */
    --z-index-modal: #{$z-index-modal};
    --z-index-nav: #{$z-index-nav};
}

html {
    font-size: calc(var(--font-base-size) + px);
}

body {
    margin: 0;
    font-family: var(--font-secondary);
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
}

a {
    color: var(--color-primary-black);
}

svg {
    display: inline-block;
}

button {
    padding: 0;
    font-weight: inherit;
    color: inherit;
    appearance: none;
    cursor: pointer;
    background-color: inherit;
    border: none;

    &:disabled {
        color: var(--color-gray-03);
        cursor: not-allowed;
    }
}

button,
a {
    &:focus-visible {
        outline: 2px solid var(--color-focus);
        outline-offset: 2px;
    }
}

b,
strong {
    font-weight: bold;
}

* {
    box-sizing: border-box;
    margin-block-start: 0;
    margin-block-end: 0;
}

[data-optimizely-hidden] {
    visibility: var(--optimizely-visibility);
}

body.challenge-shopper {
    .bb-app {
        display: none;
    }
}
