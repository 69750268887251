@import '~/bb/ui/Styles/variables';

.root {
    background-color: var(--box-background-color);
}

.borderStyles {
    border-color: var(--box-border-color);
    border-style: var(--box-border-style);
    border-width: var(--box-border-width);
}

.padding-xxs {
    @media (min-width: $screen-size-xxs) {
        padding: var(--box-padding-xxs);
    }
}

.padding-xs {
    @media (min-width: $screen-size-xs) {
        padding: var(--box-padding-xs);
    }
}

.padding-sm {
    @media (min-width: $screen-size-sm) {
        padding: var(--box-padding-sm);
    }
    border-style: border;
}

.padding-md {
    @media (min-width: $screen-size-md) {
        padding: var(--box-padding-md);
    }
}

.padding-lg {
    @media (min-width: $screen-size-lg) {
        padding: var(--box-padding-lg);
    }
}

.padding-xl {
    @media (min-width: $screen-size-xl) {
        padding: var(--box-padding-xl);
    }
}

.padding-xxl {
    @media (min-width: $screen-size-xxl) {
        padding: var(--box-padding-xxl);
    }
}
