.root {
    --star-size: 16px;
    --star-color: var(--color-primary-black);
    --star-background: var(--color-primary-black);
    --percent: calc(var(--fill) * 100%);
    position: relative;
    display: inline-block;
    font-size: var(--star-size);
    line-height: 100%;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: var(--percent);
        overflow: hidden;
        content: '★';
        background: var(--star-color);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
