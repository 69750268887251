@import './button.keyframes.module';

.root {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: var(--button-text-color);
    text-decoration: none;

    &:disabled {
        color: var(--color-gray-03);
    }
}

// Override high specificity from the .activeAnimation class.
.underline.underline.underline {
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--button-text-color);
        transform: translateX(0);
    }
}

.activeAnimation {
    &:not(:disabled) {
        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            content: '';
            background-color: var(--button-text-color);
            transform: translateX(-101%);
            animation-duration: 0.5s;
            animation-iteration-count: 0.5;
            animation-fill-mode: forwards;
        }

        &:hover {
            &::after {
                animation-name: loading;
            }
        }
    }
}

// Override high specificity from the .activeAnimation class.
.isLoading.isLoading {
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--button-text-color);
        transform: translateX(-101%);
        animation-name: loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
    }

    &:disabled {
        &::after {
            background-color: var(--color-gray-03);
        }
    }
}
