@import '~/bb/ui/Styles/variables';

.root {
    display: flex;
    justify-content: center;
    background-color: var(--color-primary-white);
    background-repeat: repeat;
    background-attachment: fixed;
    background-position-x: 50vw;
    background-position-y: 95vh;
    background-size: contain;

    @media (width >= $screen-size-sm) {
        background-image: url('https://images.ctfassets.net/4s7izhcdroyy/JYY2k0s6OyrkWK6CG8qlG/98d38255c45a07cc680b25d47e9d4df4/illustration_404_bg.png?q=85&w=960');
    }

    @media (width >= $screen-size-lg) {
        background-image: url('https://images.ctfassets.net/4s7izhcdroyy/JYY2k0s6OyrkWK6CG8qlG/98d38255c45a07cc680b25d47e9d4df4/illustration_404_bg.png?q=85&w=1920');
    }
}
