@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';
@import './button.keyframes.module';

.root {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: var(--button-text-color);
    text-decoration: none;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    backface-visibility: hidden;

    &:disabled {
        background-color: var(--color-gray-02);
        border: none;
    }

    & svg {
        color: var(--button-text-color);
        fill: var(--button-text-color);
    }
}

.activeAnimation {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: var(--button-active-color);
        transition: transform 0.25s;
        transform: translateX(-101%);
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
        &::before {
            transform: translateX(-101%) translateX(10px);
        }
    }

    &:active:not(:disabled) {
        color: var(--button-active-text-color);
        border-color: var(--button-active-color);

        &::before {
            transform: translateX(0);
        }

        & svg {
            color: var(--button-active-text-color);
            fill: var(--button-active-text-color);
        }
    }
}

.variant-contained {
    background-color: var(--button-primary-color);
}

.variant-outlined {
    border: 2px solid var(--button-primary-color);
}

.size-large {
    height: var(--button-height);
    padding: spacing(4, 5);
}

.size-small {
    height: var(--button-height-small);
    padding: spacing(2, 3);
}

.isLoadingAnimation {
    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--button-active-color);
        transform: translateX(-101%);
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    &:disabled {
        &::after {
            background-color: var(--color-gray-03);
        }
    }
}

.isLoading {
    &::after {
        animation-name: loading;
    }
}
