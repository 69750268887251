@import '~/bb/ui/Styles/functions';

.base {
    max-width: fit-content;
}

.icon {
    flex-shrink: 0;
    width: 25px;
}

.minHeight {
    min-height: 25px;
}

.text {
    padding: 0 spacing(2);
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.number {
    width: 30px;
    height: 30px;
    background-color: var(--color-primary-purple);
}
