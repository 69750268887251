@import '~/bb/ui/Styles/variables';

.content {
    width: 100%;
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(1, 0, 0, 1);
}

.children {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.headerButton {
    padding: 0;
    margin-right: var(--distance-extra-small);
    color: var(--color-primary-black);
    text-align: initial;
    cursor: pointer;
    background: transparent;
    border: 0;

    &:focus-visible {
        outline: 1px solid black;
    }
}

.arrow {
    flex: 0 0 24px;

    > svg {
        transition: transform 0.2s cubic-bezier(1, 0, 0, 1);
    }

    &.open {
        transform: rotate(90deg);
    }
}

.text {
    // TODO Improve toggleRow so that this is not needed, this is a hack that prevents the button item from overflowing the rightIcon, flex items by default have a min-width: auto, by setting it to 0 we tell the browser that the element have no right to take up more place than the width available
    min-width: 0;
    overflow-x: hidden;
}

.noAnimate {
    overflow: visible;
}
