@import '~/bb/ui/Styles/functions';

.countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

.text {
    max-width: 400px;
    height: auto;
    padding: spacing(3);
}

// Override terribly high specificity
.inline.inline.inline {
    display: flex;
}
