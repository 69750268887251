@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    gap: spacing(1);
}

.block {
    display: flex;
}

.fluid {
    display: flex;
    width: 100%;
}

.input {
    display: inline-flex;
    height: var(--input-height);
    padding: var(--input-spacing);
    overflow: hidden;
    background-color: var(--color-primary-white);
    border: 1px solid var(--input-border-color);
}

.adornment {
    display: flex;
    align-items: flex-start;
}

.startAdornment {
    padding-right: spacing(2);
}

.endAdornment {
    padding-left: spacing(2);
}

.disabled {
    color: var(--color-gray-03);
    cursor: not-allowed;
}

.disableBorder {
    border: none;
}

.disablePadding {
    padding: 0;
}
