@import '../../Styles/variables';

// LESS Functions

@mixin paddingX($distance) {
    margin-right: -$distance / 2;
    margin-left: -$distance / 2;

    & > * {
        padding-right: $distance / 2;
        padding-left: $distance / 2;
    }
}

@mixin paddingY($distance) {
    row-gap: $distance;
}

// Hortizontal gutter

.x-extraSmall {
    @include paddingX(var(--distance-extra-small));
}

.x-small {
    @include paddingX(var(--distance-small));
}

.x-medium {
    @include paddingX(var(--distance-medium));
}

.x-large {
    @include paddingX(var(--distance-large));
}

// Vertical gutter

.y-extraSmall {
    @include paddingY(var(--distance-extra-small));
}

.y-small {
    @include paddingY(var(--distance-small));
}

.y-medium {
    @include paddingY(var(--distance-medium));
}

.y-large {
    @include paddingY(var(--distance-large));
}
