@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

$easing: ease-out;

@mixin set-variables {
    top: var(--animation-top);
    right: var(--animation-right);
    bottom: var(--animation-bottom);
    left: var(--animation-left);
    width: var(--animation-width);
    height: var(--animation-height);
}

.content {
    @include set-variables;
    position: fixed;
    z-index: calc(var(--z-index-modal) + 2);
    overflow-y: auto;
    background-color: var(--color-primary-white);
    transition: transform var(--animation-time) $easing;
    transition-delay: 0ms;
    transform: var(--animation-from);
}

.showContent {
    transition-delay: var(--animation-time);
    transform: var(--animation-to);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--z-index-modal) - 1);
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 0%);
    transition: background-color var(--animation-time) $easing;
}

.showOverlay {
    background-color: rgb(0 0 0 / 30%);
}

.animationColorContainer {
    @include set-variables;
    position: fixed;
    z-index: calc(var(--z-index-modal) - 1);
}

.animationColor {
    @include set-variables;
    position: relative;
    z-index: var(--z-index-modal);

    &::before {
        @include set-variables;
        position: absolute;
        z-index: var(--z-index-modal);
        content: '';
        background-color: var(--color-secondary-red);
        transition: transform var(--animation-time) $easing;
        transition-delay: var(--animation-time);
        transform: var(--animation-from);
    }

    &::after {
        @include set-variables;
        position: absolute;
        z-index: calc(var(--z-index-modal) + 1);
        content: '';
        background-color: var(--color-primary-purple);
        transition: transform var(--animation-time) $easing;
        transition-delay: calc(var(--animation-time) / 2);
        transform: var(--animation-from);
    }
}

.showAnimationColor {
    opacity: 1;

    &::before,
    &::after {
        opacity: 1;
        transform: var(--animation-to);
    }

    &::before {
        transition-delay: 0ms;
    }

    &::after {
        z-index: calc(var(--z-index-modal) + 1);
    }
}

.hideAnimationColor {
    opacity: 0;

    &::before,
    &::after {
        opacity: 0;
    }
}

.drawerHeader {
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 30px;
}

.close {
    position: absolute;
    top: 50%;
    right: 0;
    padding: spacing(2);
    transform: translateY(-50%);
}
