.flexStart {
    justify-content: flex-start;
}

.flexEnd {
    justify-content: flex-end;
}

.center {
    justify-content: center;
}

.spaceAround {
    justify-content: space-around;
}

.spaceBetween {
    justify-content: space-between;
}
