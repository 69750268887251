.base {
    --columns: 12; /* Number of columns in the grid system */
    flex: 0 0 calc(var(--span) / var(--columns) * 100%);
}

.span-12 {
    --span: 12;
}

.span-6 {
    --span: 6;
}

.span-4 {
    --span: 4;
}

.span-3 {
    --span: 3;
}

.span-2 {
    --span: 2;
}

.span-1 {
    --span: 1;
}
