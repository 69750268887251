@font-face {
    font-family: 'Haffer';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Haffer/Bold.woff2') format('woff2'),
        url('/fonts/Haffer/Bold.woff') format('woff'),
        url('/fonts/Haffer/Bold.eot'),
        url('/fonts/Haffer/Bold.eot?#iefix') format('embedded-opentype');
    font-display: swap;
    unicode-range: U+0020-007f, U+00C0-00FF, U+0100-017F;
}

@font-face {
    font-family: 'Haffer';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Haffer/Regular.woff2') format('woff2'),
        url('/fonts/Haffer/Regular.woff') format('woff'),
        url('/fonts/Haffer/Regular.eot'),
        url('/fonts/Haffer/Regular.eot?#iefix') format('embedded-opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Haffer';
    font-style: light;
    font-weight: 300;
    src: url('/fonts/Haffer/Light.woff2') format('woff2'),
        url('/fonts/Haffer/Light.woff') format('woff'),
        url('/fonts/Haffer/Light.eot'),
        url('/fonts/Haffer/Light.eot?#iefix') format('embedded-opentype');
    font-display: swap;
    unicode-range: U+0020-007f, U+00C0-00FF, U+0100-017F;
}

@font-face {
    font-family: 'Teodor';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Teodor/Bold.woff2') format('woff2'),
        url('/fonts/Teodor/Bold.woff') format('woff'),
        url('/fonts/Teodor/Bold.eot'),
        url('/fonts/Teodor/Bold.eot?#iefix') format('embedded-opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Teodor';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Teodor/Regular.woff2') format('woff2'),
        url('/fonts/Teodor/Regular.woff') format('woff'),
        url('/fonts/Teodor/Regular.eot'),
        url('/fonts/Teodor/Regular.eot?#iefix') format('embedded-opentype');
    font-display: swap;
}
