@import '../Styles/variables';

.none {
    padding-left: 0;
}

.extraSmall {
    padding-left: var(--distance-extra-small);
}

.small {
    padding-left: var(--distance-small);
}

.medium {
    padding-left: var(--distance-medium);
}

.large {
    padding-left: var(--distance-large);
}
