@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

$extraAdornmentPadding: calc(
    var(--input-spacing) + var(--input-adornment-size) + #{spacing(2)}
);

.fluid {
    width: 100%;
}

.textarea {
    resize: none;
}

.component {
    width: 100%;
    padding: 0;
    appearance: none;
    resize: none;
    background: inherit;
    border: none;

    &:focus-visible {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        background: inherit;
    }

    &::placeholder {
        color: var(--color-gray-03);
    }
}

.wrapper {
    position: relative;

    &:focus-within {
        &::before {
            transform: translateX(0);
        }
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 8px;
        height: 100%;
        content: '';
        background: var(--input-border-color);
        transition: all 200ms;
        transform: translateX(-100%);
    }
}

.toggleButton {
    display: flex;
}

.focusIndicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background: var(--input-border-color);
    transition: all 200ms;
    transform: translateX(-100%);
}

.textareaInput {
    height: auto;
}
