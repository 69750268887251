.base {
    display: inline-block;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    color: inherit;
}

.inline {
    margin-right: 2px;
    margin-left: 2px;
}

.extraSmall {
    width: 12px;
    height: 12px;
}

.small {
    width: 20px;
    height: 20px;
}

.medium {
    width: 32px;
    height: 32px;
}

.large {
    width: 48px;
    height: 48px;
}

.invert {
    filter: invert(1);
}

.color {
    color: var(--icon-color);
}

.flag {
    height: auto;
}
