@import '../Styles/variables';

.base {
    width: 90%;
    height: 1px;
    margin: 40px auto 30px;

    @media only screen and (width >= 720px) {
        max-width: 800px;
    }
}

.small {
    margin: var(--distance-small) auto var(--distance-extra-small);
}

.green {
    background-color: var(--color-secondary-green);
}

.purple {
    background-color: var(--color-primary-purple);
}

.red {
    background-color: var(--color-secondary-red);
}
