@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

@each $name, $size in $all-screen-sizes {
    @for $i from 1 through 15 {
        .gap-#{$name}-#{$i} {
            @media (width >= #{$size}) {
                gap: spacing($i);
            }
        }
    }
}
