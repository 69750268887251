.wrap {
    flex-wrap: wrap;
}

.noWrap {
    flex-wrap: nowrap;
}

.wrapReverse {
    flex-wrap: wrap-reverse;
}
