.base {
    display: flex;
    align-items: flex-end;

    > div {
        display: inline-block;
        width: 50%;
        padding: 0 5px;

        &:first-child {
            padding: 0 5px 0 0;
        }

        &:last-child {
            padding: 0 0 0 5px;
        }
    }
}
