@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: spacing(4);

    @media (width >= $screen-size-sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (width >= $screen-size-lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (width >= $screen-size-xl) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.flag {
    width: 25px;
}

.link {
    display: block;
    padding: spacing(4);
    color: var(--color-primary-white);
    text-decoration: none;

    &:hover {
        color: var(--color-primary-black);
        background-color: var(--color-primary-purple);
    }
}

.modalContent {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    transition: all 400ms ease-in-out;
    transform: translateY(100%);

    @media (width >= $screen-size-lg) {
        height: auto;
    }

    &[data-isopen='true'] {
        transform: translateY(0);
    }
}

.limitHeight {
    max-height: calc(100% - var(--navbar-height-slim));
}

.modalInner {
    position: relative;
    padding: spacing(7, 5);

    @media (width >= $screen-size-lg) {
        padding: spacing(7, 9);
    }
}

.close {
    position: fixed;
    top: spacing(4);
    right: spacing(4);
    padding: spacing(2);
    color: var(--color-primary-white);

    @media (width >= $screen-size-lg) {
        position: absolute;
    }
}

.marketPickerButton {
    padding: spacing(4, 0);

    &:hover .marketPickerText {
        text-decoration: underline;
    }
}
