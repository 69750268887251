@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

$topSpacing: calc(#{spacing(3)} + #{var(--navbar-height)});

.root.root {
    position: fixed;
    z-index: var(--z-index-modal);

    @media (width <= $screen-size-md) {
        top: $topSpacing;
        right: initial;
        left: 50%;
        transform: translateX(-50%);
    }
}

.top-left {
    top: $topSpacing;
    left: spacing(3);
}

.top-right {
    top: $topSpacing;
    right: spacing(3);
}

.bottom-left {
    bottom: spacing(3);
    left: spacing(3);
}

.bottom-right {
    right: spacing(3);
    bottom: spacing(3);
}

.inner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: spacing(3);
}
