.root {
    position: absolute;
    left: -99999px;

    &:focus-visible {
        position: relative;
        left: auto;
        border: 2px solid var(--color-primary-white);
    }
}
