@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.box {
    top: 50%;
    left: 50%;
    min-width: 380px;
    max-width: 490px;
    max-height: calc(100vh - #{spacing(4)});
    opacity: 0;
    transition:
        opacity var(--modal-animation-duration),
        scale var(--modal-animation-duration);
    scale: 0.5;
    translate: -50% -50%;

    @media screen and (max-width: $screen-size-sm) {
        width: calc(100vw - #{spacing(4)});
        min-width: calc(100vw - #{spacing(4)});
        max-width: none;
    }

    &[data-isopen='true'] {
        opacity: 1;
        scale: 1;
    }
}
