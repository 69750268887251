@import '../Styles/variables';
@import '../Styles/functions';

.center {
    align-items: center;
}

.flexStart {
    align-items: flex-start;
}

.flexEnd {
    align-self: flex-end;
}

.zeroPadding {
    @media (width <= calc($screen-size-sm - 1px)) {
        padding: 0;
    }
}

.grow {
    flex: 1;
}
