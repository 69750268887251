.base {
    width: 100%;
    appearance: none !important;
    background-color: transparent;
    border: none;

    button:focus {
        outline: none;
    }
}
