@import '../../Styles/variables';

.message {
    width: 90%;
    padding: 10px;
    margin: 0;
    color: var(--color-secondary-red);
    text-align: center;
    cursor: default;
}

.disablePadding {
    padding: 0;
}
