@import '../../Styles/variables';

.base {
    width: 100%;
}

.flex {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.reverse {
    flex-direction: row-reverse;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}
