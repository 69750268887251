@import '~/bb/ui/Styles/functions';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    min-height: 38px;

    &.fullWidth {
        width: 100%;
    }
}

.close {
    position: absolute;
    top: spacing(2);
    right: spacing(2);

    & svg {
        $size: 20px;
        width: $size;
        height: $size;
    }
}

.icon {
    position: absolute;
    top: spacing(2);
    left: spacing(2);
}

.box {
    width: 100%;
    padding: spacing(2, 7);
    word-break: break-word;
}

.limitRows {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: var(--limitRows);
    line-clamp: var(--limitRows);
    -webkit-box-orient: vertical;
}

.showMore {
    display: initial;
    -webkit-line-clamp: unset;
    line-clamp: unset;
}

.showMoreButton {
    position: relative;
    display: block;
}
