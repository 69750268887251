@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

.root {
    // Needed to override 'width: auto;' in SimpleNotification.tsx.
    width: 300px !important;
    opacity: 0;
    transition: all var(--animationTime);
    transform: var(--transformOrigin);

    @media (width <= $screen-size-sm) {
        width: calc(100vw - #{spacing(4)}) !important;
    }
}

.isVisible {
    opacity: 1;
    transform: translateY(0);

    .progress {
        animation: progress var(--durationTime) linear;
        animation-play-state: running;
    }
}

.progress {
    width: 100%;
    height: 3px;
    background-color: var(--progress-background-color);
    transform-origin: top right;
}

@keyframes progress {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(0);
    }
}

.pauseAnimation.pauseAnimation {
    animation-play-state: paused;
}
