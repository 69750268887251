@import '../Styles/variables';

.none {
    padding-bottom: 0;
}

.extraSmall {
    padding-bottom: var(--distance-extra-small);
}

.small {
    padding-bottom: var(--distance-small);
}

.medium {
    padding-bottom: var(--distance-medium);
}

.large {
    padding-bottom: var(--distance-large);
}

.frameOuter {
    padding-bottom: 75px;
}

.frameInner {
    padding-bottom: 50px;
}
