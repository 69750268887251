@import '../Styles/variables';

.none {
    margin: 0;
}

.extraSmall {
    margin: var(--distance-extra-small);
}

.small {
    margin: var(--distance-small);
}

.medium {
    margin: var(--distance-medium);
}

.large {
    margin: var(--distance-large);
}
