@import '../Styles/variables';

.secondaryRegular {
    font-family: var(--font-secondary);
    font-weight: 400;
}

.secondaryBold {
    font-family: var(--font-secondary);
    font-weight: 700;
}

.secondaryLight {
    font-family: var(--font-secondary);
    font-weight: 300;
}

.primaryBold {
    font-family: var(--font-primary);
    font-weight: 700;
}

.primaryRegular {
    font-family: var(--font-primary);
    font-weight: 400;
}
