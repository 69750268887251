@import '../Styles/variables';
@import '../Styles/functions';

.padding {
    padding: spacing(5, 5, 5);

    @media (min-width: $screen-size-md) {
        padding: spacing(9);
    }
}
